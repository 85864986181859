// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-areas-js": () => import("./../src/pages/areas.js" /* webpackChunkName: "component---src-pages-areas-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-loc-cit-js": () => import("./../src/templates/locCit.js" /* webpackChunkName: "component---src-templates-loc-cit-js" */),
  "component---src-templates-loc-cou-js": () => import("./../src/templates/locCou.js" /* webpackChunkName: "component---src-templates-loc-cou-js" */),
  "component---src-templates-loc-ctr-js": () => import("./../src/templates/locCtr.js" /* webpackChunkName: "component---src-templates-loc-ctr-js" */),
  "component---src-templates-loc-dis-js": () => import("./../src/templates/locDis.js" /* webpackChunkName: "component---src-templates-loc-dis-js" */),
  "component---src-templates-loc-reg-js": () => import("./../src/templates/locReg.js" /* webpackChunkName: "component---src-templates-loc-reg-js" */)
}

